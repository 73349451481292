import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { ModalService } from 'src/app/@domain/modal/modal.service';
import { Modal } from '../../../../@domain/modal/modal.enum';

@Component({
    selector: 'app-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: `
        @use 'variables' as *;

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: $black;
        }

        .modal-parent {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 100;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.5s linear;
        }

        .modal-parent.open {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s, opacity 0.3s linear;

            .modal {
                max-height: 80vh;
                height: 100%;
                max-width: 46rem;
                width: 100%;
                margin-inline: auto;
                background-color: #fff;
                padding: 1rem;
                border-radius: 0.35rem;
                transform: scale(0);
                animation: pop 0.1s forwards;
            }
        }

        @keyframes pop {
            0% {
                transform: scale(0);
            }
            100% {
                transform: scale(1);
            }
        }

    `,
    template: `
        @if (isOpen()) {
            <div class="modal-parent" [class.open]="isOpen()">
                <div class="container">
                    <div class="modal" [class.isSmall]="isSmall()">
                        <ng-content/>
                    </div>
                </div>
            </div>
        }
    `
})
export class ModalComponent {
    private modalService = inject(ModalService);

    public isSmall = input(false);
    public tag = input.required<Modal>();
    public isOpen = computed(() => this.modalService.openedModal() === this.tag());
}
